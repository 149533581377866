/* body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  } */

.quotes {
    text-align: center;
    font-family: 'Archivo Black', sans-serif;
    font-family: 'Sacramento', cursive;
    font-size: 3em;
}

@media (max-width: 768px) {

    /* Adjust font size for smaller screens */
    .quotes {
        font-size: 16px;
    }
}

@media (max-width: 480px) {

    /* Further adjust font size for even smaller screens */
    .quotes {
        font-size: 14px;
    }
}

.introImage {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.abouttechiness {
    text-align: center;
}

.content {
    position: absolute;
    top: 10%;
    left: 30%;
    width: 40%;
}

.content-read-only {
    /* position: absolute; */
    /* font-size: larger; */
    margin-top: 1%;
    margin-left: 35%;
    margin-right: 35%;
    width: 30%;
}

@media (max-width: 820px) {
    .content-read-only {
        margin-top: 5%;
        margin-left: 0%;
        margin-right: 0%;
        width: 100%;
    }
}

/* Set default font-family */
#editor-container {
    font-family: "Aref Ruqaa";
    font-size: 18px;
    height: 375px;
}

.centered-button {
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    /* Additional styles if needed */
}

.all-blogs {
    display: flex;
    width: 30%;
    margin-left: 30%;
    margin-top: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.img-change {
    width: 150px;
    height: auto;
}

@media (max-width: 1000px) {
    .all-blogs {
        display: flex;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .p-change {
        display: block;
    }

    .img-change {
        width: 150px;
        height: auto;
    }
}

@media (max-width: 400px) {
    .all-blogs {
        display: flex;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
    }

    .p-change {
        display: none;
    }

    .img-change {
        width: 80px;
        height: auto;
    }
}

.blog-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content-wrapper {
    margin: 0 10px;
    /* Adjust the margin as needed */
}

.anchor-tag {
    text-decoration: none;
    color: inherit;
}

.contactfont { 
    max-width: 560px;
    max-width: 65ch;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px
}

.contactfonttitle {
    text-align: center;
    margin-top: 30px;
    font-family: 'ADLaM Display';
    font-size: 25px;
}

.cotactdetails {
    max-width: 560px;
    max-width: 50ch;
    margin: 0 auto;
    font-size: 20px;
    font-family: 'Noto Sans Adlam', sans-serif;
}